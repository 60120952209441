import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardMedia, CircularProgress, makeStyles } from "@material-ui/core";
import { cleanSingle, setCouponSelected } from "../../../redux/actions/couponsActions";
import { useDispatch, useSelector } from "react-redux";
import single_coupon from "../../../assets/icons/single_cupon.png";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    flex: 1,
    maxHeight: 240,
    maxWidth: 1200,
    //paddingRight: 18,
    //paddingLeft: 18,
    //paddingTop: 19,
    marginTop: 20,
    // borderStyle: 'solid',
    // borderWidth: 1
    backgroundColor: "white",
    borderRadius: 20,
    //marginBottom: 20,
    boxShadow: "5px 5px #cccaca94",
  },
  media: {
    height: 120,
    paddingTop: "30%",
    backgroundSize: "contain",
  },
  container: {
    flex: 1,
  },
  loading: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    margin: "25%",
  },
  btnCoupon: {
    // position: "relative",
    // left: "70%",
    // top: -10,
  },
  button: {
    padding: 10,
    border: "none",
    color: "white",
    borderRadius: "0 0 5px 5px",
    fontWeight: "lighter",
    backgroundColor: "#da1325",
    fontSize: "large",
    width: "100%",
    "&:hover": {
      backgroundColor: "#ff3344", // Cambia el color al pasar el mouse
    },
    "&:disabled": {
      backgroundColor: "#00000069", // Cambia el color al pasar el mouse
    },
  },
});

export default function Coupon({ burn, keyCupon, cupon, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);
  const { token } = useSelector((state) => state.loginReducer.userLoggedIn);

  useEffect(() => {
    setImage(cupon?.img_brief || single_coupon);
    console.log('image', cupon?.img_brief)
  }, [cupon]);

  useEffect(() => {
    setLoadingImage(false);
  }, [image]);

  const selectCoupon = () => {
    dispatch(setCouponSelected(cupon));
    history.push(token ? "/app/cupon" : "/consumidores/cupon");
    // history.push(token ? { pathname: "/app/cupon", state: { from: "cupon" } } : { pathname: "/consumidores/cupon", state: { from: "consumecupon" } });
  };

  return (
    <div key={keyCupon}>
      {/*  {
        !cupon.created_at?.length ?
          (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )
          :
          (

            <Card className={classes.root}>
              <CardHeader
                title=""
                style={{ padding: 0 }}
              />
              <CardMedia
                className={classes.media}
                image={image}
                title="Cupones"
              />
              <div className={classes.btnCoupon}>
                {
                  !burn ?
                    <button className={classes.button}
                      onClick={selectCoupon}>
                      CANJEAR
                    </button> :
                    <button className={classes.button}>
                      Canjeado el:
                      <br />
                      {cupon?.created_at.slice(0, cupon?.created_at.indexOf('T'))}
                    </button>
                }
              </div>
            </Card>

          )} */}
      <Card className={classes.root}>
        <CardHeader title="" style={{ padding: 0 }} />
        <CardMedia className={classes.media} image={image} title="Cupones" />
        <div className={classes.btnCoupon}>
          {!burn ? (
            <button className={classes.button} disabled={token ? false : true} onClick={selectCoupon}>
              VER
            </button>
          ) : (
            <button className={classes.button} disabled>
              Canjeado el: {moment(cupon?.created_at).format("DD/MM/YYYY")}
            </button>

          )}
        </div>
      </Card>
    </div>
  );
}
