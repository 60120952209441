import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({

    appBar: {
        top: 0,
        zIndex: 99,
        boxShadow: 'none',
        position: 'fixed',
        width: '100%',
        height: '10vh'
    },

    menuButtonLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        marginLeft: '0px !important'

    },
    menuButtonRight: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    title: {
        flex: 8,
        display: 'flex',
        justifyContent: 'center',
    },
    imgNavbar: {
        height: '40px',
        width: '100px',
        paddingBottom: '5px',
    },
    buttonExit: {
        backgroundColor: 'white',
        color: '#da1325',
        margin: '5px'

    },
    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.62)',
    },
    notipopover: {
        minHeight: '20vh',
        height: 'auto',
        minWidth: '95vw',
        borderRadius: '10px',
    },
    boxNoti: {
        display: 'flex',
        padding: '5px',
        justifyContent: 'center'
    },
    boxNotis: {
        padding: '0.5rem',
    }


}));